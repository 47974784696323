window.jQuery = window.$ = require('jquery');
let processInclude = require('./util');
const googleRecaptcha = require('./components/googleRecaptcha');
$(document).ready(function () {
  processInclude(require('./dataLayer'));
  // processInclude(require('./components/menu'));
  // processInclude(require('./components/cookie'));
  processInclude(require('./components/consentTracking'));
  // processInclude(require('./components/footer'));
  // processInclude(require('./components/miniCart'));
  // processInclude(require('./components/collapsibleItem'));
  processInclude(require('./components/search'));
  processInclude(require('./components/newsletterForm'));
  // processInclude(require('./components/clientSideValidation'));
  // processInclude(require('./components/countrySelector'));
  // processInclude(require('./components/toolTip'));
});
googleRecaptcha();
//require('./thirdParty/bootstrap');
require('./components/spinner');
