'use strict';

module.exports = function (actionName) {
  const googleRecaptchaClientSide = [...document.querySelectorAll('input[name="googleRecaptchaClientSide"]')];
  if (googleRecaptchaClientSide && typeof grecaptcha !== 'undefined') {
    grecaptcha.ready(function () {
      grecaptcha.execute(googleRecaptchaClientSide[0].value, { action: actionName }).then(function (token) {
        googleRecaptchaClientSide.forEach(function (input) {
          const parentForm = input.form;
          const recaptchaToken = parentForm.querySelector('[name="googleRecaptchaToken"]');
          recaptchaToken.setAttribute('value', token);
        });
      });
    });
  }
};
